.choose-us-card {
    img {
        max-width: 100%;
        object-fit: cover;
        transition: transform 0.5s ease-in;
        transform-origin: center;
    }
    h4 {
        bottom: 1.25rem;
        font-weight: 800;
        left: 50%;
        transform: translateX(-50%);
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}
.our-values-carousel {
    .carousel-item img {
        height: 400px;
    }
    @media (max-width: 468px) {
        .carousel-item img {
            height: 300px;
        } 
    }

    @media (min-width: 768px) {    
        .carousel-control-next,
        .carousel-control-prev {
            span {
                background-color: #000;
                border-radius: 50%;
                padding: .75rem;
            }
        }
        .carousel-control-next-icon,
        .carousel-control-prev-icon {
            background-size: .75rem;
        }
        .carousel-control-next {
            right: -8rem;
        }
        .carousel-control-prev {
            left: -8rem;
        }
    }
    
}