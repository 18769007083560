#contact {
    .contact-banner {
        background-image: url('/images/home-carousel3.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        height: calc(100vh - 6rem);
    }
    .location-banner {
        background-image: url('/images/contact-us.jpeg');
        background-repeat: no-repeat;
        background-size: cover;
        height: calc(100vh - 10rem);   
    }
    .icon {
        font-size: 1.5rem;
        color: #000;
    }
    .btn-submit {
        border: 1px solid rgb(36, 36, 36);
        border-radius: 0;
        color: #000;
        line-height: 1;
    }
}