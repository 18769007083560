#about-us {
  .carousel-wrapper {
    .centered-caption {
      .sub-title {
        width: 50%;
      }
    }
  }
  section {
    &.who-we-are {
      background-image: url("/images/aboutus-who-we-are-bg.png");
      background-position: bottom;
    }
    &.promises {
      padding-bottom: 4rem;
    }
    &.vision {
      background-image: url("/images/aboutus-vision.jpg");
    }
    &.setting-badan {
      position: relative;
      background-image: url("/images/aboutus-setting.jpg");
      .content-wrapper {
        .content {
          position: relative;
          flex-grow: 0.68;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #02958d;
            mix-blend-mode: multiply;
          }
          .check-list {
            list-style-type: none;
            li {
              display: flex;
              align-items: center;
              transform: translateX(200%);
              transition: 1s;
              &::before {
                content: "";
                background-image: url("/images/check-mark.png");
                height: 2rem;
                width: 2rem;
                background-size: contain;
                display: inline-block;
                margin-right: 0.5rem;
              }
            }
            li:lang(ar) {
              transform: translateX(-200%);
              &::before {
                margin-left: 0.5rem;
              }
            }
            &.active {
              li {
                transform: translateX(0);
              }
              li:nth-child(1) {
                transition-delay: 0.5s;
              }
              li:nth-child(2) {
                transition-delay: 1s;
              }
              li:nth-child(3) {
                transition-delay: 1.5s;
              }
              li:nth-child(4) {
                transition-delay: 2s;
              }
              li:nth-child(5) {
                transition-delay: 2.5s;
              }
            }
          }
        }
        .bg-white {
          background-color: #fff;
          opacity: 0.6;
          display: block;
          flex-grow: 1;
          width: 100%;
        }
      }
    }
    &.approach {
      background-image: url("/images/aboutus-approach.jpeg");
    }
    &.lifecycle {
      background-color: #e4e4e4;
      .lifecycle-img-bg {
        flex-grow: 1;
        // background-image: url("/images/aboutus-lifecycle.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
@media (max-width: 576px) {
  .vh-100 {
    height: auto !important;
    min-height: 100vh;
  }
  #about-us {
    .carousel-wrapper {
      .centered-caption {
        .sub-title {
          width: auto;
        }
      }
    }
  }
}
