section.page-section {
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    background-position-y: bottom;
    left: 0;
    z-index: 1;
    background-image: url("/images/watermark.png");
    background-repeat: no-repeat;
    height: 22vw;
    width: 22vw;
    background-size: contain;
  }
}
@media (max-width: 576px) {
  section.page-section {
    &::after {
      height: 30vh;
      width: 30vh;
    }
  }
}

//Avenir
@font-face {
  font-family: "Avenir";
  // src: url('webfont.eot'); /* IE9 Compat Modes */
  src: url("/fonts/Avenir.ttc") format("truetype"); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  // src: url('webfont.eot'); /* IE9 Compat Modes */
  src: url("/fonts/Avenir Next.ttc") format("truetype"); /* Safari, Android, iOS */
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "Avenir", Fallback, sans-serif !important;
}

body:lang(ar) {
  font-family: "Tajawal", sans-serif !important;
  text-align: right !important;
  // font-size: 1.2rem;
} /** for arabic **/

// icomoon
@font-face {
  font-family: "icomoon";
  src: url("/fonts/icomoon.eot?5junei");
  src: url("/fonts/icomoon.eot?5junei#iefix") format("embedded-opentype"),
    url("/fonts/icomoon.ttf?5junei") format("truetype"),
    url("/fonts/icomoon.woff?5junei") format("woff"),
    url("/fonts/icomoon.svg?5junei#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-map-marker:before {
  content: "\e900";
}
.icon-envelope:before {
  content: "\e901";
}
.icon-home:before {
  content: "\e902";
}
.icon-social-linkedin-circular:before {
  content: "\e903";
}
.icon-social-twitter-circular:before {
  content: "\e904";
}
.icon-social-facebook-circular:before {
  content: "\e905";
}
.icon-phone:before {
  content: "\e942";
}
