* {
  box-shadow: none !important;
  outline: 0 !important;
}
/* .font-medium {
  font-weight: 600;
} */
textarea.form-control,
input.form-control {
  color: #5d6264;
  border-color: #b3b3b3;
  border-radius: 0;
  padding: 0.625rem 1rem;
  height: unset;
}