#projects {
  .carousel-wrapper {
    .centered-caption {
      .sub-title {
        width: 85%;
      }
    }
  }
  section.page-section {
    &.ongoing-projects {
      background-image: url("/images/projects-ongoing.jpg");
      .projects-table {
        width: 100%;
        border-collapse: separate;
        height: 100vh;
        tbody {
          display: block;
          height: 70vh;
          overflow: auto;
        }
        thead,
        tbody tr {
          display: table;
          width: 100%;
          table-layout: fixed;
        }
        thead {
          width: calc(100% - 1em);
        }
        td {
          background-color: #ffffffc4;
        }
        th,
        td {
          &:first-child {
            width: auto;
          }
          &:nth-child(2) {
            width: auto;
          }
          &:last-child {
            width: 10rem;
          }
        }
        .status {
          width: 20%;
        }
        .address,
        .name {
          width: 40%;
        }
      }
    }
  }
}

.upcoming-projects {
  .carousel {
    .carousel-inner {
      height: 100%;
    }
    .carousel-media {
      object-fit: cover;
    }
    .carousel-item {
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: left;
      .project-props {
        position: relative;
        margin-left: 0;
        margin-right: 0;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #12501c;
          // mix-blend-mode: multiply;
          opacity: 0.7;
        }
        .cur-pointer {
          cursor: pointer;
        }
      }
    }
  }
}

.water-mark-project {
  content: "";
  position: absolute;
  bottom: 0;
  background-position-y: bottom;
  left: 0;
  z-index: 1;
  background-image: url("/images/watermark.png");
  background-repeat: no-repeat;
  height: 22vw;
  width: 22vw;
  background-size: contain;
}

@media (max-width: 576px) {
  .water-mark-project {
    height: 30vh;
    width: 30vh;
  }
}

.col-padding {
  padding: 1.5rem;
}
.text-wrap-pre-line {
  white-space: pre-line;
}

@media (max-width: 576px) {
  .col-padding {
    padding: 0.5rem;
  }
}
@media (max-width: 768px) {
  #projects section.page-section.ongoing-projects .projects-table {
    th,
    td {
      &:first-child {
        width: 22rem;
      }
      &:nth-child(2) {
        width: 22rem;
      }
    }
  }
}

@media (max-width: 576px) {
  #projects {
    .carousel-wrapper {
      .centered-caption {
        .sub-title {
          width: auto;
        }
      }
    }
  }
}
