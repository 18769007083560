$body-color: #858585;
$line-height-base: 1.4;

// change the theme
$theme-colors: (
  "primary": #2db180,
);
$enable-responsive-font-sizes: true;

@import 'bootstrap/scss/bootstrap.scss';
