.home-carousel {
  height: calc(100vh - 6rem);
  .carousel-inner {
    height: 100%;
  }
  .carousel-media {
    object-fit: cover;
  }
}
.centered-caption {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  .title,
  .sub-title {
    opacity: 0;
    animation: fadeIn forwards 1s;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
