#our-resources {
    // .nurseries-carousel{
    //     .nurseries-carousel-img {
    //         height: 75vh;
    //         object-fit: cover;
    //     }
    // }
    .equipment {
        .equipment-img {
            background-image: url('/images/resources-equipment.jpeg');
            height: 90vh;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
    .location {
        background-image: url('/images/project-9.jpeg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}