header {
  background: linear-gradient(to right, #12565f 47%, #8ba23c);
  .this-navbar {
    min-height: 6rem;
    .navbar-brand {
      img {
        height: 3.5rem;
      }
    }
    .language-selector {
      img {
        height: 1.5rem;
      }
    }
    a.nav-link {
      font-size: 0.8rem;
      img.home-logo {
        height: 1rem;
        width: 1rem;
        object-fit: cover;
      }
    }
    a.nav-link:lang(ar) {
      font-size: 1rem !important;
    } /** for arabic **/
  }
  .lang-select {
    font-size: 0.8rem;
    font-family: "Avenir", Fallback, sans-serif !important;
  }
}
.arabic-modal {
  max-width: 100vw !important;
  .arabic-modal-body {
    background-color: rgba($color: #879f3d, $alpha: 0.9);
  }
  .modal-content {
    background-color: transparent;
  }
}
@media (max-width: 576px) {
  header {
    .this-navbar {
      .navbar-brand {
        img {
          height: 2.8rem;
        }
      }
    }
  }
}
