#app-gallery {
  &.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5rem;
    grid-auto-rows: minmax(18rem, auto);
    .item-wrapper {
      background-color: cornsilk;
      overflow: hidden;
      position: relative;
      img.item {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: transform 0.5s ease-in;
        transform-origin: center;
      }
      .item-text-wrapper {
        position: absolute;
        z-index: 1;
        bottom: 1.5rem;
        text-align: center;
        left: 0;
        right: 0;
        // &.text-color-light {
        color: #fff;
        // }
        // &.text-color-light {
        //   color: #000;
        // }
        h4 {
          text-shadow: #000 1px 1px 2px;
        }
      }
      &:hover {
        img.item {
          transform: scale(1.1);
        }
        &::after {
          content: "";
          position: absolute;
          z-index: 0;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #3a3a3a4f;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  #app-gallery.wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 576px) {
  #app-gallery.wrapper {
    grid-template-columns: 1fr;
  }
}
